<template>
    <div class="mx-auto col-sm-2 col-md-10">
        <v-container v-if="loading.page === false" class="">
            <v-row>
                <v-col cols="12" md="9">
                    <v-card outlined elevation="0" class="mb-4 rounded-lg">
                        <v-img :src="userImg.banner" height="100" :lazy-src="userImg.banner" aspect-ratio="1"
                            class="subsecondary lighten-2">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                        class="mx-auto rounded-pill"></v-skeleton-loader>
                                </v-row>
                            </template>
                        </v-img>
                        <v-card-text class="position-relative">
                            <!-- User Avatar -->
                            <v-avatar size="75" color="white" class="avatar-center">
                                <v-img :src="userImg.profile" :lazy-src="userImg.profile" aspect-ratio="1"
                                    class="subsecondary lighten-2">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                                class="mx-auto"></v-skeleton-loader>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-avatar>
                            <div class="d-flex justify-space-between align-left">
                                <v-btn small class="rounded-lg" color="secondary" @click="joinToConnect()"
                                    v-if="isOnColaboration === true">
                                    Join to connect
                                </v-btn>
                            </div>
                            <div class="d-flex justify-space-between flex-wrap mt-4" v-if="userList !== undefined">
                                <div class="me-2">
                                    <v-card-title class="py-1 px-0">
                                        {{ currentuser.lastname + " " + currentuser.firstname }}
                                    </v-card-title>
                                    <p class="mt-1">{{ currentuser.country }}2222222</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card outlined class="mb-4 rounded-lg">
                        <div class="pa-4">
                            Networking
                        </div>
                        <v-divider claa></v-divider>
                        <v-row class="pa-4 ">
                            <v-col col="12" md="3" v-for="(item, index) in colaborators" :key="index">
                                <user-sm :user="item" class=""></user-sm>
                            </v-col>
                        </v-row>
                        <div class="pa-4">
                            <v-btn color="primary" class="mx-auto">Show more</v-btn>
                        </div>
                    </v-card>
                    <v-card outlined class="mb-4 rounded-lg">
                        <div class="pa-3">
                        </div>
                        <v-card-text class="">
                        </v-card-text>
                    </v-card>
                    <v-card outlined class="mb-4 rounded-lg">
                        <div class="pa-3">
                        </div>
                        <v-card-text class="">
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="user-profile-intro">
                        <div class="">
                            Intro
                            <hr />
                        </div>
                        <div class="user-profile-intro-item">
                            <v-icon class="item-icon">{{ icons.mdiAccount }}</v-icon>
                            <span class="item-keyword">Name</span>
                            <span class="item-value">Alain raoul</span>
                        </div>
                        <div class="user-profile-intro-item">
                            <v-icon class="item-icon">{{ icons.mdiAccount }}</v-icon>
                            <span class="item-keyword">Followers</span>
                            <span class="item-value">{{ countfriend }} friend(s)</span>
                        </div>
                        <div class="user-profile-intro-item">
                            <v-icon class="item-icon">{{ icons.mdiAccount }}</v-icon>
                            <span class="item-keyword">Email</span>
                            <span class="item-value">alainraoul@gmail.com</span>
                        </div>
                        <div class="user-profile-intro-item">
                            <v-icon class="item-icon">{{ icons.mdiAccount }}</v-icon>
                            <span class="item-keyword">LinkedIn</span>
                            <span class="item-value" color="secondary">@Alainbomba</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="ms-auto" v-else>
            <v-row>
                <v-col col="12" md="12">
                    <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
                    <div>
                        <v-skeleton-loader v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" md="3" v-for="i in 4" :key="i">
                    <v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-two-line, actions">
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import UserCard from '@/views/pages/users/components/UserCard.vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiHelpCircleOutline, mdiAccount } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import UserSm from '@/views/pages/users/components/UserSm.vue'

export default {
    components: {
        UserCard,
        UserSm
    },
    data() {
        return {
            loading: {
                page: true,
                coverimage: false,
                connection: false,
            },
            userImg: {
                profile: '',
                banner: ''
            },
            currentuser: {},
            userList: [],
            colaborators: [],
            idis: this.$route.params.ids,
            countfriend: 0,
            isOnColaboration: false,
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
        }
    },

    methods: {
        loadImage() {
            this.loading.coverimage = true;
        },

        loadLogoBanner: function (e) {
            Drequest.api(`user.logobanner?user_id=${this.idis}`).get((response) => {
                if (response.success === true) {
                    this.userImg.profile = response.logo
                    this.userImg.banner = response.banner
                }
                else { }
            }).catch((err) => { });
        },

        cancelInvitation(item) { },
        onReadyAndSendNewColaboration() {
            var formColaborator = {
                "owner": this.$store.getters.getUser.id,
                "recipient": this.$store.getters.getColaboration.recipient,
            }
            Drequest.api("colaboration.send")
                .toFormdata(formColaborator)
                .post((response) => {
                    switch (response.success) {
                        case true:
                            this.$fire({
                                type: response.etablished === true ? "error" : "success",
                                text: response.detail,
                                timer: 3000
                            })
                            break;
                        default:
                            this.$fire({
                                type: "Error",
                                text: response.detail,
                                timer: 3000
                            })
                            this.loading = false
                            break;
                    }
                })
                .catch((err) => {
                    this.$fire({
                        type: "error",
                        text: "Connexion error..!"
                    })
                    this.loading = false;
                });
        },
        joinToConnect() {
            this.$store.commit("setColaboration", {
                initialized: true,
                owner: '',
                name: this.currentuser.firstname,
                recipient: this.$route.params.ids,
            });

            if (!this.$store.getters.isLoggedIn) {
                this.$fire({
                    text: "You have to logIn..!"
                })
                this.$router.push({ name: 'login' })
            }
            else {
                if (this.isOnColaboration === false) {
                    this.onReadyAndSendNewColaboration()
                }
            }
        },
        checkColaboration() {
            if (this.$store.getters.isLoggedIn) {
                Drequest.api(`colaboration.check?owner=${this.$store.getters.getUser.id}&recipient=${this.$route.params.ids}`)
                    .get((response) => {
                        if (response.success === true) {
                            this.isOnColaboration = response.etablished
                        }
                    })
                    .catch((err) => {
                        this.loading.page = false;
                    })
            }
        }
    },
    mounted() {
        Drequest.api(`lazyloading.colaborator?dfilters=on&status=2&user_id=${this.idis}&next=1&per_page=4`)
            .get((response) => {
                if (response.success === true) {
                    this.countfriend = response.nb_element
                    response.listEntity.forEach((value, i) => {
                        this.colaborators.push(value.user)
                    })
                }
                else {
                    console.log("")
                }
            })
            .catch((err) => {
                this.$fire({
                    type: "error",
                    text: "Connexion error..!"
                })
                this.loading = false;
            });
    },
    beforeMount() {
        const currentId = this.$route.params.ids
        this.checkColaboration()
        this.loading.page = true

        this.loadLogoBanner()

        var lazyRequest = "lazyloading.user"
        if (this.$store.getters.isLoggedIn) {
            lazyRequest = `lazyloading.user?dfilters=on&id:neq=${this.$route.params.ids}&next=1&per_page=4`
        }
        Drequest.api(lazyRequest)
            .get((response) => {
                switch (response.success) {
                    case true:
                        response.listEntity.forEach((value, index) => {
                            const resData = {
                                id: value.id,
                                email: value.email,
                                firstname: value.firstname,
                                lastname: value.lastname,
                                phonenumber: value.phonenumber,
                                username: value.username,
                                adress: value.adress,
                                city: value.city,
                                lastlogin: value.last_login,
                                logo: value.logo
                            }
                            this.userList.push(resData)
                        })
                        this.loading.page = false
                        break;
                    default:
                        this.$fire({
                            type: "error",
                            text: "Veuillez recharger la page..!",
                            timer: 2000
                        })
                        break;
                }
            })
            .catch((err) => {
                this.loading.page = false;
            })


        Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$route.params.ids}`)
            .get((response) => {
                switch (response.success) {
                    case true:
                        response.listEntity.forEach((value, index) => {
                            this.currentuser = {
                                id: value.id,
                                email: value.email,
                                firstname: value.firstname,
                                lastname: value.lastname,
                                phonenumber: value.phonenumber,
                                username: value.username,
                                adress: value.adress,
                                city: value.city,
                                lastlogin: value.last_login,
                                logo: value.logo
                            }
                        })
                }
            })
            .catch((err) => {
            })
    },
    setup() {
        const icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiHelpCircleOutline,
            mdiAccount
        }

        return {
            icons
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';

.avatar-center {
    top: -2rem;
    left: 1rem;
    position: absolute;
}

.user-profile-intro {
    background: var(--v-secondaryAccent-base);
    padding: 1em 0px;
    border-radius: 0.5em;

    .user-profile-intro-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        paddin .item-icon,
        .item-keyword,
        .item-value {
            margin-right: 0.4em;
            color: var(--v-accent-base);
            opacity: 0.8;
        }

        .item-icon {
            margin-right: 0.1em;
            width: 0.7em;
        }

        .item-keyword {
            font-weight: normal;
            font-size: 12px !important;
        }

        .item-value {
            font-weight: bold;
            font-size: 13px !important;
        }
    }
}
</style>
